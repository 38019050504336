<template>
    <div>
        <div class="ky"></div>
        <div class="kf" @mouseover="open" @mouseleave="close">
                <img src="./images1/top.png">
                <img class="kefu" src="./images1/kefu.png">
            </div>
            <div class="popup-image" v-if="disabled">
                <img src="./images1/kefuqr.png" alt="" style="width: 200px;" >
            </div>

        <div class="gy"></div>
        <div class="box1" v-if="showBox1">
            <div class="tu_box">
                <div id="tu04" @click="show1"></div>
                <div id="tu05" @click="show2"></div>
                <div id="tu06" @click="show3"></div>
            </div>
        </div>
    
        <div>
            <div id="popup" class="popup">
                <div id="popup1" v-if="showPopup1" @click="close1">          
                    <img src="./images/tu01.png">
                </div>
                <div id="popup2" v-if="showPopup2" @click="close1">              
                    <img src="./images/tu03.png">
                </div>
                <div id="popup3" v-if="showPopup3" @click="close1">            
                    <img src="./images/tu02.png">
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from './Footer';
import Header from './Header';

export default {
  
    components:{
        Footer,
        Header
    },
    data(){
        return{
            disabled:false,
            showBox1:true,
            showPopup1:false,
            showPopup2:false,
            showPopup3:false,
            showClose:false
        }
    },
    methods:{
        open(){
            this.disabled=!this.disabled
        },
        close(){
            this.disabled=false
        },
        show1(){
            this.showPopup1=!this.showPopup1
            this.showBox1=!this.showBox1
            this.showClose=true
        },
        show2(){
            this.showPopup2=!this.showPopup2
            this.showBox1=!this.showBox1
            this.showClose=true
        },
        show3(){
            this.showPopup3=!this.showPopup3
            this.showBox1=!this.showBox1
            this.showClose=true
        },
        close1(){
            this.showPopup1=false
            this.showBox1=true
            this.showPopup2=false
            this.showPopup3=false
            this.showClose=false
        }
    }

}
</script>

<style scoped>
/* 整体移动距离 */
@media (min-width: 769px){
    .ky{
        background-image: url('./images1/kaiye.png');
        background-size:cover;
        overflow: hidden;
        z-index: 5;
        width: 99vw;
        height: 1240px;
    }
    .gy{
        width:99vw;
        height:1250px;
        background-image: url('./images1/gongyin.png');
        background-size: cover;
        overflow:hidden;
        z-index: 1;
    }

    /* 三个盒子的背景 */
    /* 三个立牌之间的样式 */
    .tu_box{
        background-image: url('./images1/tu31.png');
        background-size: cover;
        position: relative;
        display: flex;
        height: 785px;
        width: 95vw;
        justify-content:center;
        margin-top: 5%;
        margin-bottom: 5%;
        
    }
    /* 立牌本身的大小样式 */
    .tu_box div{
        margin: 150px 0 100px 0;
        width:22%;
        height: 72%;
        background-size: cover;
        /* 上下浮动效果 */
        transition: margin 1.0s ease,width 1.0s ease;
    }
    /* 立牌触碰后立起来 */
    .tu_box div:hover{
        margin: -5px 0 ;
        width:24%;
        height: 73%;
        /* margin-left: 8%; */
    }
    #tu04{
        background-image: url('./images1/tu3.png');
        transform: translate(0,-20%);
    }
    #tu05{
        background-image: url('./images1/tu4.png');
        transform: translate(0,-20%);
    }
    #tu06{
        background-image: url('./images1/tu5.png');
        transform: translate(0,-20%);
    }
    #tu04:hover{
        background-image: url('./images1/tu3s.png');
    }
    #tu05:hover{
        background-image: url('./images1/tu4s.png');
    }
    #tu06:hover{
        background-image: url('./images1/tu5s.png');
    }

    /* 三个立牌点击事件后图片的弹出 */
    .popup{
        position: relative;
        width: 100vw;
    }
    .popup img{
        width: 98vw;
        height: 960px;
        z-index: 5000;
    }
    /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
    .kf{
        position: fixed;
        right: 3%;
        bottom: 10%;
        z-index: 50;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        right: 3%;
        bottom: 8%;
        z-index: 50;
    }

    /* 想要使用position: absolute(绝对定位功能)，最好配套 position: relative(相对位置) */
    .popup-image {
        position: fixed;
        bottom: 10%;
        z-index: 50;
        right: 9%;
    }

    .popup-image img{
        width:200px;
    }
}

@media (max-width:768px){
    
}
</style>