<template>
    <div>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <div class="shop"></div>
        <div class="kf" @mouseover="open" @mouseleave="close">
            <img class="top" src="./images1/top.png">
            <img class="kefu" src="./images1/kefu.png">
        </div>
        <div class="popup-image" v-if="disabled">
            <img src="./images1/kefuqr.png" alt="" >
        </div>
    </div>
  
</template>

<script>
//引入Header组件
import Header from './Header';

export default {
    name:'Shop',
    components:{
        Header //注册Header组件
    },
    data(){
        return{
            disabled:false,
        }
    },
    methods:{
        open(){
            this.disabled=!this.disabled
        },
        close(){
            this.disabled=false
        }
    }

}
</script>

<style scoped>
@media (min-width:760px) {
    .shop{
        background-image: url('./images1/shop.png');
        background-size: cover;
        width:1900px;
        height: 920px;
    }
        
   /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
   .kf{
        position: fixed;
        right: 3%;
        bottom: 10%;
        z-index: 50;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        right: 3%;
        bottom: 8%;
        z-index: 50;
    }

    /* 想要使用position: absolute(绝对定位功能)，最好配套 position: relative(相对位置) */
    .popup-image {
        position: fixed;
        bottom: 10%;
        z-index: 50;
        right: 9%;
    }
    
    .popup-image img{
        width:200px;
    }
    
}

</style>