//该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
//引入组件
import Home from '@/components/Home'
import About from '@/components/About'
import Contact from '@/components/Contact'
import Culture from '@/components/Culture'
import New from '@/components/New'
import Shop from '@/components/Shop'


//创建并暴露一个路由器
export default new VueRouter({
    routes:[
        {
        path:'/home',
        component:Home,
        meta:{title:'首页'},
    },
    {
        path:'/',
        redirect:'/home'  //没有设置时，默认打开界面是白色，设置这个时，路由会默认跳转到'/home'
    },
    {
        path:'/about',
        component:About,
        meta:{title:'了解世宇'},
    },
    {
        path:'/shop',
        component:Shop,
        meta:{title:'商场'},
    },
    {
        path:'/culture',
        component:Culture,
        meta:{title:'全开文化'},
    },
    {
        path:'/new',
        component:New,
        meta:{title:'最新动态'},
    },
    {
        path:'/contact',
        component:Contact,
        meta:{title:'联系我们'},
    },]
})