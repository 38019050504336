<template>  
    <div class="header" >
        <div :class="{'container' : visabled1}">
            <div class="nav">
                <div class="logo-box">
                    <img :class="{'logo' : visabled2}" src="./images1/logo_m.png" >
                </div>
                <div :class="{'list-group' : visabled3}" >
                    <div class="list-group-box">
                        <router-link class="list-group-item" active-class="active" to="/home">首页</router-link>
                        <router-link class="list-group-item" active-class="active" to="/about">了解世宇</router-link>
                        <router-link class="list-group-item" active-class="active" to="/shop">商城</router-link>
                        <router-link class="list-group-item" active-class="active" to="/culture">全开文化</router-link>
                        <router-link class="list-group-item" active-class="active" to="/new">最新动态</router-link>
                        <router-link class="list-group-item" active-class="active" to="/contact">联系我们</router-link>
                    </div>
                </div>
                <div :class="{'search-icon-box' : visabled4}">
                    <img class="search-icon" src="./images1/search.png" alt="搜索图标" @click="search" >
                </div>
                        
                
            </div>
        </div>

        <!-- 模态框 -->
        <div class="popup" v-if="disable">
            <button class="close-button" @click="close">
                <img src="./images1/close2.png" alt="">
            </button>
            <input type="text" id="search-input" placeholder="请输入探索内容" >
            <img src="./images1/search.png" id="search2" alt="">
        </div>   
            
        <el-main @scroll="handScroll()" class="el-main">
            <router-view></router-view>
        </el-main>
    </div> 
</template>  
     
<script>  
export default {  
    name: 'Header',  
    // 这里可以添加组件的数据、方法等  
    data(){
        return{
            disable:false,  //给显示框一个布尔值确定显隐
            visabled1:false, //给显隐区域一个布尔值确定显隐
            visabled2:true,
            visabled3:true,
            visabled4:true,
        }
    },
    methods:{
        search(){
            this.disable=!this.disable  //取反值让搜索框区域显示
        },
        close(){
            this.disable=false    //关闭搜索框
        },
        handScroll(){
            //计算滚动位置
            const position=window.scrollY
            if(position >= 20){
                console.log('111')
                this.visabled1=true
                this.visabled2=false
                this.visabled3=false
                this.visabled4=false
            }else{
                this.visabled1=false
                this.visabled2=true
                this.visabled3=true
                this.visabled4=true
            }
        },
    },
    //挂载方法
    mounted(){
        window.addEventListener('scroll',this.handScroll)
    },
    //关闭前销毁方法
    beforeDestroy(){
        window.removeEventListener('scroll',this.handScroll)
    }
} 
</script>  
     
<style scoped>  
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "思源黑体", "Source Han Sans", sans-serif;
}
@media (min-width:769px){
    .container{
        margin-left: 360px;
        width: 1200px;
        position: fixed;
        left: 20px;
        overflow: hidden;
        /* padding: 50px; */
        background-color: white;
        height: 60px;
        z-index: 1000;
        margin-top: 50px;
        /* 给盒子添加阴影效果 */
        box-shadow: 2px 2px 20px 2px black;
        animation: slide 10s infinite alternate;
        border-radius: 50px;
    }
    /* 使用@keyframes定义动画 */  
    @keyframes slide {  
    from {  
        transform: translateY(0); /* 动画开始时，盒子在原始位置 */  
    }  
    to {  
        transform: translateY(5px); /* 动画结束时，盒子向下滑动200px */  
    }  
    }
    .nav{
        position: absolute;
        display: flex;
    }
    /* 新的突破！logo包裹一个盒子，让logo在顶部出现，下滑时隐藏出现装载logo盒子 */
    .logo-box{
        width: 500px;
        margin-top: 10px;
        height: 30px;
    }
    .logo-box img{
        height: 34px;
    }
    .logo{
        height: 30px;
        margin-left: 300px;
        margin-right: 150px;
    }

    /* 头部导航区盒子层层相叠，环环相扣啊！ */
    .list-group{
        width: 600px;
        height: 50px;
        text-align: center;
        /* margin:60px; */
        margin-left: 400px;
        /* margin-top: 18px; */
    }
    /* 头部导航区字体颜色，可变为白色，隐藏后消失 */
    .list-group .list-group-box .list-group-item{
        color: white;
    }
    /* 通过:hover伪类来实现鼠标经过时颜色会自动变颜色 */
    .list-group .list-group-box .list-group-item:hover{
        color: blue;
        /* transition: color 0.1s; 颜色过度，使颜色更平滑 */
    }
    .list-group .list-group-box .active {  
        color: blue; /* 激活状态的颜色 */  
        font-weight: bolder;  
    }

    .list-group-box{
        margin-top: 18px;
    }

    .list-group-item{
        font-size: 18px;
        text-decoration: none;
        font-weight: bold;
        color: black;
        padding: 35px;
        margin-left: -35px;
    }
    /* 通过:hover伪类来实现鼠标经过时颜色会自动变颜色 */
    .list-group-item:hover{
        color:blue
    }

    /* 搜索图标，先用可以隐藏的盒子来标识图标颜色为白色，下滑隐藏后图标颜色变为黑色 */
    .search-icon-box .search-icon{
        filter: brightness(1);
    }
    .search-icon{
        cursor: pointer;
        margin-right: 100px;
        height: 30px;
        /* 把搜索图标染为黑色 效果要么为0，要么为1 */
        filter: brightness(0);
        margin-top: 20px;
    }

    /* 模态框区域样式 */
    .popup{
        background-color: rgba(37, 89, 187, 0.308);
        position: fixed;
        z-index: 500;
        top:11.5%;
        right: 14%;
        height: 50px;
        border: 2px solid #b9b2b9;
        border-radius: 15px;
    }

    #search2{
        background-color: rgba(37, 89, 187, 0.308);
        height: 36px;
        transform: translate(0,15%);

    }
    #search-input{
        padding: 5px;
        transform: translate(0%,-20%);
        border-radius: 15px;
    }
    /* 搜索框显隐的关闭按钮区域样式 */
    .close-button {
        float: left;
        font-size: 1px;
        font-weight: bold;
        border-radius: 8px 8px 8px 8px;
        width: 30px;
        height: 30px;
        margin-top: 4%;
    }
    .close-button img{
        transform: translate(-15%,0);
        height: 22px;
    }

    .close-button:hover,
    .close-button:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    .active {  
        color: blue; /* 激活状态的颜色 */  
        font-weight: bolder;  
    }

    .el-main{
        padding: 0px !important;
    }
}

/* 移动端 */
@media (max-width:768px) {
    
}


</style>