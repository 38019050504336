<template>
    <div>
        <div class="sy">
            <div class="desc">
                <p class="p1"> 广东世宇科技股份有限公司成立于1994年，总部位于广东中山。世宇秉承“用心创造，把欢乐和健康带给千家万户”的使命，
                    <span style="color: #0380FF">公司集游戏游艺娱乐设备的研发、生产、销售；数字体育、家庭游戏以及游戏场地系统解决方案的运营、礼品供应链等一站式服务的多元化企业。</span>
                    公司已全面通过质量、环境、职业健康安全、知识产权贯标管理体系认证，荣获国家文化出口重点企业、高新技术企业、广东省专精特新中小企业、
                    广东省商用游戏游艺机工程技术研究中心等多项资质认定。</p>
                <p class="p2"> 
                    ——构建建筑面积为12万平方米的制造生产基地，居同行业前沿;<br>
                    ——拥有先进的生产设备和检测仪器，并不断引进高新生产设备和工艺;<br>
                    ——下设5个子公司，在广州、香港、加拿大等国内外多个地区设有分公司。<br> 
                </p>
            </div>
        </div>
 

        <div class="kf" @mouseover="open" @mouseleave="close">
                <img src="./images1/top.png">
                <img class="kefu" src="./images1/kefu.png">
            </div>
            <div class="popup-image" v-if="visabled">
                <img src="./images1/kefuqr.png" alt="" >
            </div>
    
        <div class="box1" style="display: flex; justify-content: center; ">
            <div class="yuanjing" v-for="(image,index) in images" :key="index" @mouseover="toggleImage(index)" @mouseleave="leave(index)">
                <img v-if="image.disabled1" :src="image.src" >
                <img v-if="image.disabled" :src="image.hiddenImg">
                <span>{{ image.title }}</span>
            </div>
        </div>
    
        <div class="tu9">
            <img src="./images1/tu39.png" />
        </div>
        <div class="tu10">
            <img src="./images1/tu40.png" alt="">
        </div>
        <div class="tu11">
            <img src="./images1/tu41.jpg" alt>
        </div>
        <div class="tu12">
            <img src="./images1/tu42.png">
        </div>
        <div class="tu13">
            <div class="tu14"></div>
            <img class="left" src="./images1/left.png" alt="" @click="prev">
            <img class="right" src="./images1/x_right.png" alt="" @click="next"> 

            <div class="xingxiang-tab1" v-show="disabled1">
                <div class="box01">
                    <img src="./images1/tu43.png" alt=""> 
                    <img src="./images1/tu47.png" alt="" @click="open1()"> 
                    <img src="./images1/tu48.png" alt="" @click="open2()"> 
                </div>
                
                
                <div class="xingxiang1" > 
                    <img 
                    :class="['imgCss',ShowImg==index ? 'imgCss' : '']" 
                    v-for="(img,index) in dataImage1" :key="index" 
                    :src="img" 
                    v-show="index===ShowImg" />
                    <!-- 使用判断语句控制面板图片的多少 -->
                </div>
                
            </div>
    
            <div class="xingxiang-tab2" v-show="disabled2">
                <div class="box02">
                    <img src="./images1/tu46.png" alt="" @click="open3()">
                    <img src="./images1/tu44.png" alt="">
                    <img src="./images1/tu48.png" alt="" @click="open4()">
                </div>
                <div class="xingxiang2">
                    <img 
                    :class="['imgCss',ShowImg===index ? 'ShowCss' : '']"
                    v-for="(img,index) in dataImage2" :key="index"
                    :src="img"
                    v-show="index===ShowImg"/>
                </div>
            </div>

            <div class="xingxiang-tab3" v-show="disabled3">
                <div class="box03">
                    <img src="./images1/tu46.png" alt="" @click="open5()">
                    <img src="./images1/tu47.png" alt="" @click="open6()">
                    <img src="./images1/tu45.png" alt="">
                </div>
                <div class="xingxiang3">
                    <img :class="['imgCss',ShowImg===index ? 'Show' : '']"
                    v-for="(img,index) in dataImage3" :key="index"
                    :src="img"
                    v-show="index===ShowImg"/>
                </div>
            </div>

            <!-- 指示器 -->  
            <div class="carousel-indicators">  
            <button   
                v-for="(img, index) in dataImage1"   
                :key="index"   
                :class="{ active: index === ShowImg }"  
                @click="ShowImg = index"  
            ></button>  
            </div> 

            <div class="tu15"></div>
        </div>
       
        
        <div class="hz">
            <img src="./images1/tu20.png" class="hz1" alt>
            <img src="./images1/pinpai.png" class="hz2" alt="合作伙伴">
        </div>

        <Footer />
    </div>
</template>

<script>
import Footer from './Footer'
import Header from './Header'
export default {
    name:'About',
    components:{
        Header,
        Footer
    },
    data(){
        return{
            images:[
                {src:require('./images1/tu36.png'),disabled1:true,hiddenImg:require('./images1/tu33.png'),disabled:false,title:'用心创造，把欢乐和健康带给大家'},
                {src:require('./images1/tu37.png'),disabled1:true, hiddenImg:require('./images1/tu34.png'),disabled:false,title:'成为一家卓越的的企业'},
                {src:require('./images1/tu35.png'),disabled1:true, hiddenImg:require('./images1/tu38.png'),disabled:false,title:'人品企品产品三品合一'},
            ],
            dataImage1:[
                require('./images/tu75.jpg'),
                require('./images/tu76.jpg'),
                require('./images/tu77.jpg'),
                require('./images/tu78.jpg'),
                require('./images/tu79.jpg'),
                require('./images/tu80.jpg'),
            ],
            dataImage2:[
                require('./images/tu81.jpg'),
                require('./images/tu82.jpg'),
                require('./images/tu83.jpg'),
                require('./images/tu84.jpg'),
                require('./images/tu85.jpg'),
                require('./images/tu86.jpg'),
            ],
            dataImage3:[
                require('./images/tu87.jpg'),
                require('./images/tu88.jpg'),
                require('./images/tu89.jpg'),
                require('./images/tu90.jpg'),
                require('./images/tu91.jpg'),
                require('./images/tu92.jpg'),
            ],
            visabled:false,
            ShowImg:0,  //表示当前显示的图片
            disabled1:true,  //表示当前盒子的显隐
            disabled2:false,
            disabled3:false,
        }
    },
    methods:{
        open(){
            this.visabled=!this.visabled
        },
        close(){
            this.visabled=false
        },
        toggleImage(index){
            if(index>0){
                this.images[index].disabled =  !this.images[index].disabled
                this.images[index].disabled1= !this.images[index].disabled1
                this.images[0].disabled1=false
                this.images[0].disabled=true
            }else{
                this.images[0].disabled1=true
                this.images[0].disabled=false
            }
            
            
        },
        leave(index){
            if(index>0){
                this.images[index].disabled= false
                this.images[index].disabled1= true
                this.images[0].disabled1=true
                this.images[0].disabled=false
            }
        },
        //上一张
        prev(){
            if(this.ShowImg!==0){
                this.ShowImg--
            }else{
                this.ShowImg=this.dataImage1.length-1
            }
            this.currentIndex=(this.currentIndex - 1 +this.dataImage5.length) % this.dataImage5.length
        },
        //下一张
        next(){
            if(this.ShowImg!==this.dataImage1.length-1){
                this.ShowImg++
            }else{
                this.ShowImg=0
            }
            this.currentIndex=(this.currentIndex + 1) % this.dataImage5.length
        },
        open1(){
            this.disabled2= !this.disabled2
            this.disabled1=false
        },
        open2(){
            this.disabled3= !this.disabled3
            this.disabled1=false
        },
        open3(){
            this.disabled2=!this.disabled2
            this.disabled1=true
        },
        open4(){
            this.disabled3= !this.disabled3
            this.disabled2=false
        },
        open5(){
            this.disabled3= !this.disabled3
            this.disabled1=true
        },
        open6(){
            this.disabled3= !this.disabled3
            this.disabled2=true
        },
    }
}
</script>



<style scoped>
@media (min-width: 769px){
    /* 顶部首页样式以及文字 */
    .sy{
        background-image: url('./images1/tu32.jpg');
        background-size: cover;
        overflow: hidden;
        height: 2500px;
        width:99vw;
        bottom: 0;
        z-index: -50;
        position: relative;
    }
    .sy .desc{
        font-size: 1.25rem;
        position: absolute;
        left: 20%;
        width: 60%;
        bottom:30%;
        /* 设置移动，正为右下，负为左上 */
        /* transform: translate(0%,-400%); */
    }
    /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
    .kf{
        position: fixed;
        right: 3%;
        bottom: 10%;
        z-index: 1000;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        right: 3%;
        bottom: 8%;
        z-index: 1000;
    }

    /* 想要使用position: absolute(绝对定位功能)，最好配套 position: relative(相对位置) */
    .popup-image {
        position: fixed;
        bottom: 10%;
        z-index: 1000;
        right: 9%;
    }

    .popup-image img{
        width:200px;
    }
    /* 三个盒子之间的来回切换 */
    .yuanjing{
        position: relative;
        z-index: 50;
    }
    .yuanjing img{
        /* 调整盒子图片区域大小，插件会帮自适应 */
        height: 850px;
    }
    .yuanjing span{
        position: absolute;
        font-size: 1.4rem;
        bottom: 20%;
        left: 5%;
        width: 30%;
        /* 垂直排列 */
        /* writing-mode: vertical-lr; */
    }
    /* 背景图样式，企业荣誉背景图 */
    .tu9{
        /* background-image: url('./images1/tu39.png');
        background-size: cover; */
        width: 95%;
        height: 250px;
        transform: translate(0,-150%);
        /* background-color: tomato; */
    }
    .tu9 img{
        height: 600px;
    }
    /* 企业荣誉图片样式 */
    .tu10{
    /* transform: translate(10%,-100%); */
    width: 100%;
    text-align: center;
    }
    .tu10 img{
        height: 132px;
    }
    /* 企业荣誉奖状图片样式 */
    .tu11{
        /* 图片居中位置 */
        text-align: center;
    }
    .tu11 img{
        height: 550px;
    }
    /* 企业形象图片样式 */
    .tu12{
        /* 图片居中 */
        text-align: center;
        margin-top: 8%;
    }
    .tu12 img{
        height: 138px;
    }
    .tu13{
        margin-top: 5%;
        width: 100%;
        position: relative;
    }
    /* 左右箭头样式 */
    .left{
        position: absolute;
        bottom: 30%;
        left: 12%;
        /* width: 45px; */
        height: 45px;
    }
    .right{
        position: absolute;
        bottom: 30%;
        right: 12%;
        height: 45px;
    }
    /* 三个小盒子之间的样式，环境，展品，车间 */
    .box01 {
        width: 70%;
        margin-left: 30%;
        margin-bottom: 5%;
    }
    .box01 img{
        margin-right: 5%;
        height: 70px;
    }
    .box02 {
        width: 70%;
        margin-left: 30%;
        margin-bottom: 5%;
    }
    .box02 img{
        margin-right: 5%;
        height: 70px;
    }
    .box03 {
        width: 70%;
        margin-left: 30%;
        margin-bottom: 5%;
    }
    .box03 img{
        margin-right: 5%;
        height: 70px;
    }
    /* 用来存放图片的盒子 3个盒子 */
    .xingxiang1{
        /* 装载图片盒子居中 */
        text-align: center;
    }
    .xingxiang1 img{
        height: 490px;
    }
    .xingxiang2{
        /* 装载图片盒子居中 */
        text-align: center;
    }
    .xingxiang2 img{
        height: 490px;
    }
    .xingxiang3{
        /* 装载图片盒子居中 */
        text-align: center;
    }
    .xingxiang3 img{
        height: 490px;
    }
    /* 装载图片的背景图 */
    .tu15{
        background-image: url('./images/tu95.png');
        background-size: cover;
        width: 100%;
        height: 1200px;
        position: absolute;
        top: 0%;
        right: 0;
        z-index: -50;
    }

    /* 合作伙伴区域盒子的样式 */
    .hz{
        background-image: url('./images1/tu26.png');
        width: 100%;
        /* 盒子整体图片居中排列 */
        text-align: center;
        margin-bottom: 10%;
        margin-top: 15%;
    }
    .hz1{
        height: 110px;
        }
    .hz2{
        height: 620px;
    }
    /* 指示器外面的大盒子 */
    .carousel-indicators{
        margin-top: 20px;
        transform: translate(270%,0);
        /* 给个宽度限制太宽超出屏幕造成额外导航长度 */
        width: 300px;
    }
    .carousel-indicators button {  
    /* 样式化按钮作为圆点 */  
        height: 18px;  
        border: solid rgb(90, 140, 197);  
        border-radius: 50%;  
        background-color: white;  
        margin: 0 5px;  
        cursor: pointer;  
        outline: none; /* 移除焦点轮廓 */  
        margin-left: 15px;
        margin-right: 15px;
    }  
    /* 指示器按钮激活时的颜色 */
    .carousel-indicators button.active {  
        background-color: #336ff0; 
        opacity: 1;   
    }
}

/* 移动端 */
@media (max-width:768px) {
    
}
  

</style>