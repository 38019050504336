//引入Vue
import Vue from 'vue'
//引入App
import App from './App.vue'
//引入路由器
import router from './router';
//引入VueRouter
import VueRouter from 'vue-router';
//引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入px转换为rem
import './rem'

import 'normalize.css'

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

//关闭Vue的生产提示
Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueRouter)

//创建vm
new Vue({
	el:'#app',
	router,
	render: h => h(App)
})