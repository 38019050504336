<template>
  <div class="app">
    <Header></Header>
  </div>
</template>

<script>
import Header from '@/components/Header'


export default {
  components: {Header},

}
</script>
