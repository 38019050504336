<template>
  <div>
    <div class="tu"></div>
    <div class="kf" @mouseover="open" @mouseleave="close">
        <img class="top" src="./images1/top.png">
        <img class="kefu" src="./images1/kefu.png">
    </div>
    <div class="popup-image" v-if="disabled">
        <img src="./images1/kefuqr.png" alt="">
    </div>
    <div class="box">
        <div class="box01">
            <div class="title">国内外业务</div>
            <img class="xian" src="./images/tu44.png" alt="">
            <div class="content">
                <div>
                    <p>世宇科技官方企业微信</p>
                </div>
                <img class="wx" src="./images1/qr6.png" alt=""> 
            </div>
        </div>

        <div class="box02">
            <div class="title">潮玩业务</div>
            <img class="xian" src="./images/tu44.png" alt="">
            <div class="content">
                <div>
                    <p>宇宙潮玩小程序</p>
                </div>
                <img class="cw" src="./images1/qr7.png" alt="">
            </div>
        </div>
    </div>
    <div class="box2">
        <div>
            <div class="title">售后服务</div>
            <img calss="xian" src="./images/tu44.png" alt="">
            <div class="content">
                <div>
                    <p>世宇客服小程序</p>
                    <p>售后热线：0760-89982755</p>
                </div>
                <img class="fw" src="./images1/qr8.png" alt="">
            </div>
        </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './Footer'
export default {
    name:'Contact',
    components:{
        Footer
    },
    data(){
        return{
            disabled:false
        }
    },
    methods:{
        open(){
            this.disabled=!this.disabled
        },
        close(){
            this.disabled=false
        }
    }
    
}
</script>

<style scoped>
@media (min-width: 769px) {
    /* 顶部大头照 */
    .tu{
        background-image: url('./images1/tu1.png');
        background-size: cover;
        height: 74rem;
        width: 98vw;
    }
    /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
    .kf{
        position: fixed;
        right: 2%;
        top:70%;
        z-index: 50;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        right: 2%;
        top:78%;
        z-index: 50;
    }
    /* 二维码图标 */
    #popupImage{
        position: fixed;
        top:60%;
        z-index: 50;
        right: 8%;
    }

    .title{
        font-size: 1.25rem;
        font-weight: 600;
        color: black;
        margin-bottom: 30px;
    }

    .p{
        font-size: 0.95rem;
        font-weight: 400;
        color: black;
    }
    .wx{
        margin-top: 16px;
        width: 120px;
        height: 120px;
    }

    .cw{
        margin-top: 16px;
        width: 120px;
        height: 120px;
    }
    .fw{
        margin-top: 16px;
        width: 120px;
        height: 120px;
        margin-right: 10%;
    }
    .box{
        
        margin-left: 450px;
        width:75%;
        height: 45vh;
        display: flex;
        padding-top: 20px;
    }
    .box02{
        margin-left: 5%;
    }
    .box2{
        position: relative;
        margin-left: 450px;
        width: 28vw;
        margin-bottom: 5%;
    }

    /* 图标二维码，排列分布 */
    .contact_contact{
        display: flex;
        width: 600px;
        justify-content: space-between;
    }
    .content{
        display: flex;
        margin: 0;
    }
    .content img{
        margin-left: 180px;
    }

    /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
    .kf{
        position: fixed;
        right: 3%;
        bottom: 10%;
        z-index: 50;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        right: 3%;
        bottom: 8%;
        z-index: 50;
    }

    /* 想要使用position: absolute(绝对定位功能)，最好配套 position: relative(相对位置) */
    .popup-image {
        position: fixed;
        bottom: 10%;
        z-index: 50;
        right: 9%;
    }

    .popup-image img{
        width:200px;
    }
}

/* 移动端 */
@media (max-width:768px) {
    /* 顶部大头照 */
    .tu{
        background-image: url('./images1/tu1.png');
        background-size: cover;
        height: 74rem;
        width: 98vw;
    }
    /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
    .kf{
        position: fixed;
        right: 3%;
        bottom: 10%;
        z-index: 50;
    }
    .top{
        height: 300px;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        height: 180px;
        right: 2%;
        bottom: 9%;
        z-index: 50;
    }

    /* 想要使用position: absolute(绝对定位功能)，最好配套 position: relative(相对位置) */
    .popup-image {
        position: fixed;
        bottom: 10%;
        z-index: 50;
        right: 9%;
    }

    .popup-image img{
        width:200px;
    }
}


</style>