<template>
    <div class="box1">
            <div id="foot">
            </div>
            <div class="box01">
                <div class="icon">
                    <span class="iconfont icon-weixin"></span>
                    <span class="iconfont icon-weibo"></span>
                    <span class="iconfont icon-douyin"></span>
                    <span class="iconfont icon-bilibili"></span>
                </div>
                <div class="h">
                    <span style="font-weight: bolder;">合作网站</span>|                 
                    <a target="_blank">阿里巴巴</a>
                    <a target="_blank">中国游艺</a>
                </div>
                <!-- 触碰出现图片，离开图片消失 -->
                <div class="tag">
                    <div class="tag11">
                        <div class="popup-image1" v-if="disable1">
                            <img src="./images1/qr1.png" alt="Extra Image">
                        </div>
                        <div class="tag1"  @mouseover="open1" @mouseleave="close1">
                            <span>全开文化</span>
                        </div>
                    </div>
                    <div class="tag22">
                        <div class="popup-image2" v-if="disable2">
                            <img src="./images1/qr2.png" alt="Extra Image">
                        </div>
                        <div class="tag2" @mouseover="open2" @mouseleave="close2">
                            <span>世宇科技</span>
                        </div>
                    </div>
                    <div class="tag33">
                        <div class="popup-image3" v-if="disable3">
                            <img src="./images1/qr3.png" alt="Extra Image">
                        </div>
                        <div class="tag3" @mouseover="open3" @mouseleave="close3">
                            <span>世宇潮玩</span>
                        </div>
                    </div>
                    <div class="tag44">
                        <div class="popup-image4" v-if="disable4">
                            <img src="./images1/qr4.png" alt="Extra Image">
                        </div>
                        <div class="tag4" @mouseover="open4" @mouseleave="close4">
                            <span>世宇客服</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="box02">
                <h2>广东世宇科技股份有限公司</h2>
                <div id="c2">
                    <h3>中山市世宇实业有限公司</h3>
                    <p id="address2">
                        <span>地址|中国广东省中山市西区政安路1号(广珠西线中山西出口旁)</span>
                    </p>
                    <div class="box03">
                        <p id="phone2">
                            <span>电话|（86-760）8881 8889</span>
                        </p>
                        <p id="cz2">
                            <span>传真|（86-760）89982736</span>
                        </p>
                    </div>
                </div>
                <div id="c3">
                    <h3>广东世宇科技股份有限公司广州分公司</h3>
                    <p id="address3">
                        <span>地址|广州市番禺区东环街迎星东路8号 星力动漫游戏产业园89铺</span>
                    </p>
                    <div class="box03">
                        <p id="phone3">
                            <span>电话|（86-760）2388 5277</span>
                        </p>
                        <p id="cz3">
                            <span>传真|（86-760）8998 2736</span>
                        </p>
                    </div>
                </div>
            </div>
        </div> 
</template>

<script>
//引入图标样式
export default {
    name:'Footer',
    data(){
        return{
            disable1:false,
            disable2:false,
            disable3:false,
            disable4:false
        }
    },
    methods:{
        open1(){
            this.disable1=!this.disable1
        },
        close1(){
            this.disable1=false
        },
        open2(){
            this.disable2=!this.disable2
        },
        close2(){
            this.disable2=false
        },
        open3(){
            this.disable3=!this.disable3
        },
        close3(){
            this.disable3=false
        },
        open4(){
            this.disable4=!this.disable4
        },
        close4(){
            this.disable4=false
        }
    }
}
</script>

<style scoped>
@media (min-width: 769px) {
    .box1{
        position: relative;
        justify-content: space-between;
        align-items: center;
        /* background-color: tomato; */
        width: 100%;
        height: 30rem;
    }


    /* 几个小图标区域样式 */
    .icon{
        position: absolute;
        top: 40%;
        right: 58%;
        transform: scale(2.2);
    }
    .icon span{
        margin-left: 0.8rem;
    }

    /* 合作网站 */
    .h{
        color: aliceblue;
        position: absolute;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 22px;
        width: 50%;
        right: 18%;
        top: 55%;
    }
    /* 合作网站区域文字样式 */
    .h a{
        margin-left: 1%;
        font-weight: 200;
        /* 去掉下划线 */
        text-decoration: none;
    }

    /* 合作网站右边文字整体样式 */
    .box03{
        display: flex;
    }

    /* 左边底下栏 */
    /* 文字以及触碰出现图片样式 */
    /* 全开文化区域模块tag1 */
    .tag{
        display: flex;
        position: relative;
        font-size: 1.4rem;
        justify-content: space-between;
        width: 50vw;
        transform: translate(0,1550%);
    }
    .tag11{
        margin-left: 10%;
        color: white;
        
    }
    .popup-image1 img{
        width: 100px;
        left: 10%;
        height: 150px;  
    }
    .tag22{
        margin-left: 25px;
        color: white;
    }
    .popup-image2 img{
        width: 100px;
        left: 35%;
        height: 150px;  
    }
    .tag33{
        margin-right: 25px;
        color: white;
    }
    .popup-image3 img{
        width: 100px;
        left: 55%;
        height: 150px;  
    }
    .tag44{
        margin-right: 10%;
        color: white;
    }
    .popup-image4 img{
        width: 100px;
        left: 80%;
        height: 150px;  
    }
    .tag img{
        position: absolute;
        top:-11rem;
    } 


    /* 双导航栏的罪魁祸首 */
    /* 右边盒子区域内容 */
    .box02{
        color: aliceblue;
        transform: translate(192%,45%);
        width: 30vw;
        height: 18rem;
        position: relative;
        /* background-color: thistle; */
    }
    .box02 h2{
        font-size: 32px;
    }
    #c2 h3{
        position: absolute;
        top:35px;
    }
    #address2{
        position: absolute;
        top: 75px;
        margin-left: 4%;
    }
    #phone2{
        position: absolute;
        top: 105px;
        margin-left: 4%;
    }
    #cz2{
        position: absolute;
        top:105px;
        margin-left: 54%;
    }

    #c3 h3{
        position: absolute;
        bottom: 60px;
    }
    #address3{
        position: absolute;
        bottom: 23px;
        margin-left: 4%;
    }
    #phone3{
        position: absolute;
        margin-left: 4%;
        bottom: -10px;
    }
    #cz3{
        position: absolute;
        margin-left: 54%;
        bottom: -10px;
    }
    #foot{
        background-image: url('./images1/foot.png');
        background-size: cover;
        overflow: hidden;
        height: 520px;
        width:99vw;
        bottom: 0;
        z-index: -50;
        position: absolute;
    }
}

/* 移动端 */
@media (max-width:768px) {
}
    

    /* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 4614090 */
  src: url('//at.alicdn.com/t/c/font_4614090_fsr5fctb7t.woff2?t=1720503082436') format('woff2'),
       url('//at.alicdn.com/t/c/font_4614090_fsr5fctb7t.woff?t=1720503082436') format('woff'),
       url('//at.alicdn.com/t/c/font_4614090_fsr5fctb7t.ttf?t=1720503082436') format('truetype');
}
.iconfont {
    color: aliceblue;
    font-family: "iconfont" !important;
    font-size: 18px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
.icon-weibo:before {
  content: "\e619";
}

.icon-weixin:before {
  content: "\e63f";
}

.icon-bilibili:before {
  content: "\e656";
}

.icon-douyin:before {
  content: "\e600";
}

.icon-dianhua:before {
  content: "\e605";
}

.icon-dizhi:before {
  content: "\e657";
}

.icon-chuanzhen:before {
  content: "\e6b3";
}



</style>