<template>
    <div >
        <div class="slider">
            <div class="slider-container">
                <img
                v-for="(image, index) in images"  
                :key="index"  
                :src="image"  
                :style="{ display: index === currentIndex ? 'block' : 'none' }" >
            </div>
        </div>


 
        <!-- 触碰出现图片 -->
        <div class="kf" @mouseover="open" @mouseleave="close">
            <img src="./images1/top.png">
            <img class="kefu" src="./images1/kefu.png">
        </div>
        <div class="popup-image" v-if="disabled">
            <img src="./images1/kefuqr.png" alt="" >
        </div>

        <div class="lj">
            <img src="./images1/tu21.png">
        </div>
        <div class="lj-box">
            <!-- <img src="./images1/tu22.png" scrolling="no"> -->
            <div class="p-box">
                <p>广东世宇科技股份有限公司成立于1994年，总部位于广东中山。世宇秉承“用心创造，把欢乐和健康带给千家万户”的使命，公司集游戏游艺娱乐设备的研发、生产、销售；数字体育、家庭游戏以及游戏场地解决方案的运营、礼品供应链等一站式服务的多元化企业。</p>
                <p>公司已经全面通过质量、环境、职业健康安全、知识产权贯标管理体系认证，荣获国家文化出口重点企业、高新技术企业、广东省专精特新中小企业、广东省商用游戏游艺机工程技术研究中心等多项资质认定。</p>
                <img src="./images1/tu24.png" id="more">
            </div>
        </div>

     <div class="number-box">
        <div class="number-container" v-for="(item,index) in data" :key="index">
            <div class="num-group">
                <div class="number">
                    <animate-number from="0" :to="item.num" duration="2000"></animate-number>
                </div>
                <p class="desc">{{ item.name }}</p>
            </div>
        </div>
     </div>

        <div class="desc-container">
            <div class="desc02">服务行业</div>
            <div class="desc02">生产基地</div>
            <div class="desc02">拥有专利</div>
            <div class="desc02">覆盖国家地区</div>
        </div>


        <div class="slide-container">
            <div class="cp">
                <img src="./images1/tu17.png" alt="产品类型">
            </div>
            <div class="slider-indicators">
                <div class="tu10">
                    <img src="./images1/tu25.png">
                </div>
                <img src="./images1/left.png" class="jt-left" @click="switchBox">
                <div class="cp-box01" v-if="showBox==='box1'">
                    
                    <div class="indicator1">
                        <div class="tu" v-for="(img,index) in dataImages1" :key="index" @click="showImage(index)">
                            <img :src="img" >
                        </div>
                    </div>
                    
                </div>      
                
                <div class="cp-box02" v-if="showBox==='box2'">
                
                    <div class="indicator2">
                        <div class="tu" v-for="(img,index) in dataImages2" :key="index" @click="showImage2(index)">
                            <img :src="img" >
                        </div>
                    </div>
                        
                </div>    

                <img src="./images1/right.png" class="jt-right" @click="switchBox">

                <div class="popup01">        
                    <div v-if="showHidden===4" class="hidden-image">
                        <img src="./images1/tu11.jpg" class="tu01" @click="hideImage">
                    </div>
                    <div v-if="showHidden===5" class="hidden-image">
                        <img src="./images1/tu12.jpg" class="tu01" @click="hideImage"> 
                    </div>
                    <div v-if="showHidden===6" class="hidden-image">
                        <img src="./images1/tu13.jpg" class="tu01" @click="hideImage"> 
                    </div>
                </div>
                
                <div class="popup02">
                    
                    <div v-if="showHidden===7" class="hidden-image">
                        <img src="./images1/tu14.jpg" class="tu01" @click="hideImage">
                    </div>
                    <div v-if="showHidden===8" class="hidden-image">
                        <img src="./images1/tu15.jpg" class="tu01" @click="hideImage"> 
                    </div>
                    <div v-if="showHidden===9" class="hidden-image">
                        <img src="./images1/tu16.jpg" class="tu01" @click="hideImage"> 
                    </div>
            
                </div>

            </div>
        </div>
        <div class="hz">
            <img src="./images1/tu20.png" class="hz1" alt>
            <img src="./images1/pinpai.png" class="hz2" alt="合作伙伴">
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './Footer'
export default {
    name:'Home',
    components:{
        Footer
    },
    data(){
        return{
            showHidden:null, //控制隐藏图片的显示
            showBox:'box1',
            disabled:false,
            currentIndex:0, //当前显示的图片索引
            intervalId:null, //轮播定时器 ID
            data:[
                {
                    num:30,
                    name:'年'
                },
                {
                    num:12,
                    name:'万m²'
                },
                {
                    num:300,
                    name:'+'
                },
                {
                    num:100,
                    name:'+'
                },
            ],
            images:[
                require('./images1/slider01.jpg'),
                require('./images1/slider02.jpg'),
                require('./images1/slider03.jpg'),
                require('./images1/slider05.jpg'),
              
            ],
            dataImages1:[
                require('./images1/mini1.png'),
                require('./images1/mini2.png'),
                require('./images1/mini3.png'),
            ],
            dataImages2:[
                require('./images1/mini4.png'),
                require('./images1/mini5.png'),
                require('./images1/mini6.png'),
            ]
        }
    },
    methods:{
        //切换下一张图片
        nextImage(){
            this.currentIndex=(this.currentIndex + 1) % this.images.length  //求余获取图片索引值
        },
        start(){
            this.currentIndex=setInterval(this.nextImage,2000);
        },
        stop(){
            if(this.intervalId){
                clearInterval(this.intervalId)
                this.intervalId=null
            }
        },
        open(){
            this.disabled=!this.disabled
        },
        close(){
            this.disabled=false
        },
        switchBox(){
            this.showBox=this.showBox === 'box1' ? 'box2' : 'box1'
        },
        startSwitch(){
            setInterval(this.switchBox,2000)
        },
    
        showImage(index){
            const hiddenIndex = index + 4
            this.showHidden=hiddenIndex
        },
        showImage2(index){
            const hiddenIndex = index + 7
            this.showHidden=hiddenIndex
        },
        hideImage(){
            this.showHidden = null
        }
        
    },

    //把方法挂载
    mounted(){
        this.start()
        this.startSwitch()
    },
    //在销毁前把方法销毁掉
    beforeDestroy(){
        this.stop()
        clearInterval(this.intervalId)
    }
   
}
</script>

<style scoped>
/* 要想使用自适应大小，要准确到盒子里面的图片或者文字，而不是单单外面的盒子 */
/* 暂时发现：自适应一般设置高度，宽度会自己跟进改变 */
html,
body {
    width: 100%;
    height: 2000px;
    margin: 0;
    padding: 0;
    font-family: "思源黑体", "Source Han Sans", sans-serif;
}
/* PC端 */
@media (min-width:769px) {
    .container02 .footer{
        width: 100vw;
        height: 51vh;
    }
    /* 轮播图 */
    /* 控制轮播图图片的限制大小 */
    .slider-container img{
        width: 100%; /* 根据需要调整 */  
        height: 100%;  
        display: none; /* 默认不显示，通过v-bind:style控制显示 */  
    }
    
    .slider{
        width: 100%;
        overflow: hidden;
        height: 100%;
    }
 
    
    /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
    .kf{
        position: fixed;
        right: 3%;
        bottom: 10%;
        z-index: 50;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        right: 3%;
        bottom: 8%;
        z-index: 50;
    }

    /* 想要使用position: absolute(绝对定位功能)，最好配套 position: relative(相对位置) */
    .popup-image {
        position: fixed;
        bottom: 10%;
        z-index: 50;
        right: 9%;
    }
    
    .popup-image img{
        width:200px;
    }
    /* 了解区域的样式 */
    .lj img{
        height: 200px;
        width: 720px;
    }
    .lj-box{
        background-image: url('./images1/tu22.png');
        background-size: cover;
        position: relative;
        width: 98vw;
        /* 容器大小 */
        height: 850px;
    }
    /* 文字内容 */
    .p-box{
        position: absolute;
        font-size: 1.4rem;
        width: 30%;
        top: 20%;
        left: 65%;
    }
    .p-box img{
        position: absolute;
        right: 0%;
        height: 50px;
    }

    /* 年月日数字以及字体的样式 */
    /* .number-container{
    } */
     .number-box{
        display: flex;
        justify-content: center;
     }
     /* .number-container{
        flex: 1;
     } */
    .num-group{
        /* display: inline-block; */
        display: flex;
        margin-left: 80px;
        margin-right: 110px;
    }   
    .desc{
       font-size: 22px;
    }
    /* 数字字体区域 */
    .number{
        font-size: 5rem;
        color:rgb(37, 98, 212);
        font-weight:bolder;
        
    }
    

    /* 数字下面的字体区域 */
    .desc-container{
        display: flex;
        font-size: 1.5rem;
        justify-content: space-between;
        margin-left: 18%;
        margin-right: 21%;
        font-weight: 400;
    }

    /* 产品类型区域 */
    .cp{
        margin-top: 50px;
    }
    .cp img{
        height: 190px;
    }

    /* 产品区域盒子，给个高度限制，不然下面会有留白 */
    .slider-indicators{
        height: 860px;
        position: relative;
    }
    /* 背景图 */
    .tu10 img{
        width: 99vw;
        height: 765px;
    }
    .tu{
        margin-left: 200px;
        /* background-color: tomato; */
        transform: translate(0,-25%);

    }
    .tu img {
        height: 445px;
    }
    /* 产品第一个盒子里面的图片 */
    .indicator1{
        display: flex;
        width:100%;
     
    }
   /* 产品第一个盒子里面的图片 */
    .cp-box01{
       display: flex;
       height: 480px;
       transform: translate(0,-140%);
    }
    /* 产品箭头样式 */
    .jt-left{
        height: 45px;
        margin-top: 10%;
        transform: translate(250%,-1250%);
    }
    .jt-right{
        height: 45px;
        margin-top: 10%;
        /* 向左移动 */
        transform: translate(3650%,-2850%);
    }

    /* 产品第二个盒子样式 */
    .cp-box02{
        display: flex;
        height: 480px;
        transform: translate(0,-140%);
     }
    .indicator2{
        display: flex;
        width: 100%;
    }
 
    /* 产品隐藏的图片调整样式 */
    .popup01{
        transform: translate(12%,-195%);
        /* background-color: tomato; */
        width: 80%;
    }
    .popup02{
        transform: translate(12%,-195%);
        width: 80%;
    }
    .tu01{
        height: 1000px;
    }


     /* 合作伙伴区域盒子的样式 */
    .hz{
        background-image: url('./images1/tu26.png');
        width: 100%;
        /* 盒子整体图片居中排列(要在主盒子-父级,使用才有效果)*/
        text-align: center;
        margin-bottom: 10%;
        margin-top: 50px;
    }
    .hz1{
        height: 110px;
    }
    .hz2{
        height: 620px;
    }
   
}

/* 移动端 */
@media (max-width:768px){
    
}

</style>