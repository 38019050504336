<template>
    <div>
        <div class="sy"></div>
        <div class="kf" @mouseover="open" @mouseleave="close">
            <img src="./images1/top.png">
            <img class="kefu" src="./images1/kefu.png">
        </div>
        <div class="popup-image" v-if="disabled">
            <img src="./images1/kefuqr.png" alt="" >
        </div>
        <div class="ry">
            <div>
                <img class="tu04" src="./images1/tu54.png">        
                <img class="tu01" src="./images1/tu51.png">
            </div>
            
            <div class="p-box">
                <span class="p1">2023.11</span>
                <span class="p2">狂揽五项《金手指》大奖!世宇科技实力彰显游戏游艺设备品牌与价值输出典范</span>
                <span class="p3">匠心筑梦，载誉前行!感恩有您</span>
                <img class="more" src="./images1/tu24.png" alt="更多">
                <div class="tu03">
                    <img src="./images1/tu52.png">
                </div>
            </div>      
        </div>

        <div class="dt">
            <div class="div">
                <div class="divv" v-for="(image,index) in dataShow" :key="index">
                    <div class="tu1">
                        <img :src="image.src3"/>
                        <img :src="image.src1" class="img1" />
                    </div>
                    <div class="tu2">
                        <sapn class="data1">{{ image.data1 }}</sapn>
                        <span>{{ image.title1 }}</span>
                        <span class="title2">{{ image.title2 }}</span>
                        <span class="data2">{{ image.data2 }}</span>
                    </div>
                    <div class="tu3">
                        <img :src="image.src2" />
                    </div>
                    
                    
                </div>
                <div class="pagination">
                    <img src="./images/tu11.png" alt="左箭头" @click="prev" :style="{cursor: 'pointer',}">
                    <div>
                        <img v-for="(img2,index) in img2" :key="index" :src="img2"/>
                        <div class="yema">
                            <span v-for="i in pageNum" :key="i.id" @click="page(i)" >{{ i }}</span>
                        </div>
                    </div>
                    <img src="./images/tu12.png" alt="右箭头" @click="next" :style="{cursor: 'pointer',}">
                </div>
            </div>
        </div>         
        <Footer></Footer>
    </div>
    
</template>

<script>
import Header from './Header'
import Footer from './Footer';
export default {
    name:'New',
    components:{
        Header,
        Footer
    },
    data(){
        return{
            disabled:false,
            pageNum:1, //共几页=所有数据/每页现实数量
            pagesize:5,    //每页显示的数据
            dataShow: [],  //当前显示的数据
            totalPage:[],  //所有分页数据
            currentPage:0, //默认当前显示第一页
            dataImage:[
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
                {
                    src1:require('./images/tu74.png'),data1:'2023.11', title1:'捷报——世宇科技荣膺IAAPA铜环奖最佳新品第一名盲盒宇宙 Over the Edge',
                    title2:'荣誉时刻，共同见证',data2:'2023.11.17',src2:require('./images1/more.png'),src3:require('./images/tu13.png'),
                },
            ],
            img2:[
                require('./images1/tu66.png'),
                require('./images1/tu65.png'),
                require('./images1/tu65.png'),
            ],
        }
    },
    mounted(){
        this.pageNum=Math.ceil(this.dataImage.length/this.pagesize) || 1 //计算有多少页数据,默认为一
        //循环页面
        for(let i=0;i<this.pageNum;i++){
            this.totalPage[i]=this.dataImage.slice(this.pagesize * i,this.pagesize * (i+1))
            console.log(123);
        }
         // 获取到数据后默认显示第一页内容
         this.dataShow = this.totalPage[this.currentPage]
         
    },
    methods:{
        open(){
            this.disabled=!this.disabled
        },
        close(){
            this.disabled=false
        },
        
       
        // 上一页
        prev() {
            if (this.currentPage === 0) return 
            this.dataShow = this.totalPage[--this.currentPage]
        },
         // 下一页
         next() {
            if (this.currentPage === this.pageNum - 1) return 
            this.dataShow = this.totalPage[++this.currentPage]
        },
        // 点击页码
        page(i){
            this.currentPage = i
            this.dataShow = this.totalPage[i-1]
        }
        
          

        
    }
}
</script>

<style scoped>
/* PC端 */
@media (min-width:769px){
    .sy{
        background-image: url("./images1/tu50.png");
        background-size: cover;
        z-index: -5;
        width: 99vw;
        height: 1250px;
    }

    /* 使图标或者图片固定在表层，跟随屏幕移动而移动 */
    .kf{
        position: fixed;
        right: 2%;
        top:70%;
        z-index: 50;
    }
    /* 客服图标 */
    .kefu{
        position: fixed;
        right: 2%;
        top:78%;
        z-index: 50;
    }
    /* 二维码图标 */
    .popup-image {
        position: fixed;
        top:60%;
        z-index: 50;
        right: 8%;
    }
    .popup-image img {
        width:200px;
    }
    /* 荣誉大区域块样式 */
    /* 最外围大盒子 */
    .ry{
        display: flex;
        width: 95vw;
        height: 40rem;
        margin-top: 5%;
    }
    .tu04{
        height: 575px;
        width: 1000px;
        margin-left: 46px;
        margin-bottom: 15px;
    }
    .tu01{
        transform: translate(3%,-100%);
        height: 610px;
    }
    .tu03{
        transform: translate(-150%,-104%);
        z-index: -2;
    }
    .tu03 img{
        height: 600px;
    }
    /* 装载文字的小盒子 */
    .p-box{
        display: flex;
        flex-direction: column;
        margin-left: -15%;
        width: 40%;
    }
    .p-box img{
        transform: scale(1);
        margin-left: 70%;
        margin-top: 45%;
        
    }
    /* 字体大小的调整 p1-p3*/
    .p1{
        font-size: 50px;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        color: rgb(48, 48, 187);
        font-weight: bolder;
        /* 文本左对齐 */
        text-align: left;
        transform: translate(0,100%);
    }
    .p2{
        font-size: 30px;
        opacity: 1.0;
        transform: translate(0,150%);
    }
    .p3{
        font-size: 22px;
        opacity: 0.6;
        transform: translate(0,650%);
    }
    /* 动态消息主盒子 */
    .div{
        margin: 5%;
    }
    /* 动态消息区域样式 */
    .divv{
        display: flex;
        justify-content: center;
        margin: 30px;
        
    }
    /* 动态消息左边盒子两张图片重叠,用定位实现 */
    .tu1{
        position: relative;
    }
    .img1{
        position: absolute;
        left: 1%;
        top: 10%;
    }

    /* 动态消息右边盒子文字区域 */
    .tu2{
        display: flex;
        /* 垂直排列,要搭配display:flex使用 */
        flex-direction: column;
        margin-left: 5%;
        font-size: 26px;
        font-weight: 500;
    }
    /* 给文字区域之间加点边距 */
    .tu2 span{
        padding-bottom: 30px;
    }
    .data1{
        color: rgb(120, 172, 233);
        font-size: 45px;
        font-weight: bolder;
    }
    .title2{
        opacity: 0.5;
    }
    .data2{
        opacity: 0.5;
    }
    .tu3{
        background-color: tomato;
        position: relative;
    }
    .tu3 img{
        position: absolute;
        bottom: 0;
    }
    /* 分页器区域 */
    .pagination{
        position: relative;
        display: flex;
        justify-content: center;
        margin: 30px;
        /* background-color: tomato; */
        
    }
    .pagination img{
        height: 40px;
        margin-left: 8px;
    }
    .yema{
        cursor: pointer;
        /* margin: 20px; */
        font-size: 26px;
        transform: translate(0%,-130%);
        /* background-color: aqua; */
    }
    .yema span{
        margin: 18px;
        z-index: 50;
    }

    .active {  
        color: blue; /* 激活状态的颜色 */  
        font-weight: bolder;  
    }
}

/* 移动端 */
@media (max-width:768px){
    
}



</style>
